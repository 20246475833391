/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}
