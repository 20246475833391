@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-Light.ttf) format('truetype');
  font-weight: lighter;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-LightItalic.ttf) format('truetype');
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-Light.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-Italic.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-Medium.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-SemiBold.ttf) format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-Bold.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}


body {
  font-family: "Open Sans", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #e64f00; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#defaultCanvas0 {
  display: none;
}
#defaultCanvas1 {
  display: none;
}
#defaultCanvas2 {
  display: none;
}
#defaultCanvas3 {
  display: none;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.circle-picker {
  width: 100% !important;
}

.hue-picker {
  width: 100% !important;
  height: 36px !important;
}

.hue-horizontal {
  border-radius: 28px !important;
  cursor: pointer;
}

.hue-horizontal:active {
  cursor: grabbing;
}

.hue-horizontal > div > div {
  height: 44px !important;
  width: 21px !important;
  transform: translate(-9px, -3px) !important;
  border: 5px solid #ffffff !important;
  background-color: transparent !important;
  border-radius: 22px !important;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.18) !important;
  cursor: grab;
}

.hue-horizontal > div > div:active {
  cursor: grabbing;
}

.chrome-picker {
  width: 100% !important;
  border-radius: 22px !important;
  cursor: pointer;
  height: 114px !important;
  box-shadow: none !important;
}

.chrome-picker > div:nth-child(2) {
  display: none;
}

.chrome-picker > div {
  border-radius: 22px !important;
  height: 114px !important;
}

.chrome-picker > div:nth-child(1) > div > div > div:nth-child(2) > div {
  height: 44px !important;
  width: 44px !important;
  border: 5px solid #ffffff;
  border-radius: 50% !important;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.18);
  cursor: grab;
  transform: translate(-50%, -50%) !important;
}

.chrome-picker > div:nth-child(1) > div > div > div:nth-child(2) > div:active {
  cursor: grabbing;
}

.chrome-picker:active {
  cursor: grabbing;
}

.saturation-white {
  height: 114px !important;
  border-radius: 22px !important;
}

.saturation-black {
  height: 114px !important;
  border-radius: 22px !important;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.chrome-picker > div:nth-child(1) > div {
  height: 114px !important;
  border-radius: 22px !important;
}

html.a-fullscreen canvas.a-canvas {
  transform: translateX(-10%);
}

@media only screen and (max-width: 800px) {
  html.a-fullscreen canvas.a-canvas {
    margin: 0 auto auto;
    height: 100% !important;
    width: 100% !important;
    transform: translateY(-7.5%);
  }
}

.modal-backdrop {
  z-index: 95;
}

.first-flex-child {
  order: -1;
}

.blinker {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
}
